<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    > 
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="/">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
 
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "cast",
            title: "What is CodeMonkes Avatar?",
            desc: ` CodeMonkes Avatar is a collection of 10,000 unique NFTs issued by creative and  ERC404i Protocol`,
          },
          {
            icon: "layers",
            title: "What is ERC404i Protocol?",
            desc: ` ERC404 is a protocol based on ERC721 and ERC20 , but Avatar use self-improved ERC404, named it ERC404i.`,
          },
          {
            icon: "users",
            title: "How about the allocation of tokens?",
            desc: `50% for airdrop/40% for public mint/10% for team/1 CodeMonkes Avatar NFT = 100,000,000 Avatar FTtoken.`,
          },
          {
            icon: "clock",
            title: "When will the mint start?",
            desc: `It will be start on 3rd of March 20:00:00(UTC+8).`,
          },
          {
            icon: "book",
            title: "How to mint CodeMonkes Avatar?",
            desc: `You can only mint from theavatar.club website using Ethereum supported wallet.`,
          },
          {
            icon: "monitor",
            title: "What is the benefit to own CodeMonkes Avatar?",
            desc: ` We will set up a community to give more benefits to holders.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
