<template>
  <div class="rn-slider-area interactive-slider">
    <div
      class="slide slide-style-2 slider-paralax rn-paralax d-flex align-center justify-center bg_image bg_image"
      data-black-overlay="8"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner text-left">
              <span>Avatar - ERC404i PFP NFT</span>
              <h1 class="heading-title">
                Quantum NFT Liquidity Model Protocal
              </h1>
              <div class="slide-btn">
                <a @click="scrollToSection" class="rn-button-style--2 btn-solid"
                  >Mint Now</a
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Web3 from "web3";
export default {
  components: {},
  computed: {
    ...mapGetters(["account", "chainId"]),
    ...mapState(["web3"]),
  },
  methods: {
    scrollToSection() {
      if (!this.web3 || !this.web3.account) {
        //alert("Please Connect Wallet First!");
        if (!this.web3 || !this.web3.account) {
          console.log("connecting");
          this.$store.dispatch("connectWeb3");
        }
        return;
      }
      document
        .getElementById("mint-section")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
