<template>
  <!-- Start Brand Area -->
  <v-row class="mt--30">
    <v-col cols="12">
      <ul class="brand-style">
        <li v-for="(brand, i) in brandImages" :key="i">
          <a :href="brand.url" target="_blank">
            <img :src="brand.src" alt="Logo Images" />
          </a>
        </li>
      </ul>
    </v-col>
  </v-row>
  <!-- End Brand Area -->
</template>

<script>
  export default {
    data() {
      return {
        brandImages: [
          {
            src: require("../../assets/images/logo/genify.svg"),
            url: "https://twitter.com/GenifyHQ",
          },
          {
            src: require("../../assets/images/logo/9527.jpg"),
            url: "https://twitter.com/brc9527",
          },
          {
            src: require("../../assets/images/logo/web3u.jpg"),
            url: "",
          },
          {
            src: require("../../assets/images/logo/bob.png"),
            url: "https://btctomoon.com/",
          },
          {
            src: require("../../assets/images/logo/ubox.jpg"),
            url: "https://ubox.io/",
          },
          {
            src: require("../../assets/images/logo/lambda.jpeg"),
            url: "https://twitter.com/Lambdaim",
          },
        ],
      };
    },
  };
</script>
